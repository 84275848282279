import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { Badge } from '@/components/ui/badge'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { formatDateWithTime } from '@/lib/formatting'
import { cn } from '@/lib/utils'
import { IArchive } from '@/types'
import {
  CheckCircleIcon,
  ExternalLinkIcon,
  ChevronDownIcon,
} from 'lucide-react'
import { useState } from 'react'

interface Props {
  archive: IArchive
}

export function OnChainDetails(props: Props) {
  const { archive } = props

  const [detailsOpen, setDetailsOpen] = useState(false)
  return (
    <Alert className="bg-indigo-200">
      <CheckCircleIcon className="h-4 w-4" />
      <AlertTitle>Saved on Bitcoin</AlertTitle>
      <AlertDescription>
        <div>
          This page was saved on Bitcoin
          {archive.mintedAt
            ? ` on ${formatDateWithTime(archive.mintedAt)}`
            : ''}
          .
        </div>
        <Collapsible open={detailsOpen} onOpenChange={setDetailsOpen}>
          <CollapsibleContent
            className={cn(
              'grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-[auto,1fr]',
              {
                'mt-4': detailsOpen,
              },
            )}
          >
            <div className="font-semibold">Type:</div>
            <div>
              <Badge variant={archive.fullText ? 'default' : 'secondary'}>
                {archive.fullText ? 'Full Text' : 'Hash Only'}
              </Badge>
            </div>
            <div className="font-semibold">Transaction:</div>
            <div>
              <a
                href={`https://blockchair.com/bitcoin/transaction/${archive.txid}`}
                target="_blank"
                rel="noreferrer noopener"
                className="flex gap-1 text-sm text-indigo-600 underline-offset-4 hover:underline"
                style={{ wordBreak: 'break-all' }}
              >
                {archive.txid}{' '}
                <ExternalLinkIcon className="h-4 w-4 flex-shrink-0" />
              </a>
            </div>
            <div className="font-semibold">Ordinal:</div>
            <div>
              <a
                href={`https://ordiscan.com/inscription/${archive.ordinalId}`}
                target="_blank"
                rel="noreferrer noopener"
                className="flex items-center gap-1 text-sm text-indigo-600 underline-offset-4 hover:underline"
                style={{ wordBreak: 'break-all' }}
              >
                {archive.ordinalId}{' '}
                <ExternalLinkIcon className="h-4 w-4 flex-shrink-0" />
              </a>
            </div>
          </CollapsibleContent>
          <div
            className={`flex justify-start ${cn({
              'mt-4': detailsOpen,
              'mt-2': !detailsOpen,
            })}`}
          >
            <CollapsibleTrigger className="flex items-center gap-0.5 text-sm text-indigo-600 underline-offset-4 sm:hover:underline">
              {detailsOpen ? 'Hide' : 'Show'} details{' '}
              <ChevronDownIcon
                className={cn({
                  '-rotate-180': detailsOpen,
                  'transition-transform': true,
                })}
              />
            </CollapsibleTrigger>
          </div>
        </Collapsible>
      </AlertDescription>
    </Alert>
  )
}
