import { Link, Outlet } from 'react-router-dom'
import { AppRoutes } from './routes'
import { Toaster } from './components/ui/toaster'
import { Search } from './components/Search'
import { FeedbackButton } from './components/FeedbackButton'

export function Layout() {
  return (
    <div className="flex min-h-dvh flex-col">
      <header className="flex flex-col items-center justify-between gap-2 px-4 py-4 sm:flex-row lg:px-6">
        <Link to={AppRoutes.HOME}>
          <img src="/logo-black.png" alt="Ark" className="h-6" />
        </Link>
        <div className="mt-2 flex w-full flex-1 flex-col-reverse items-center justify-end gap-4 sm:mt-0 sm:flex-row">
          <Search />

          <nav className="flex items-center gap-4 font-medium">
            <Link to={AppRoutes.BROWSE}>Browse</Link>
            <Link to={AppRoutes.STATS}>Stats</Link>
            <Link to={AppRoutes.ABOUT}>About</Link>
          </nav>
        </div>
      </header>
      <main className="flex flex-1 flex-col px-4 pb-20 md:px-6">
        <Outlet />
      </main>
      <footer className="flex items-center justify-center gap-4 px-4 py-4 md:px-6">
        <Link to={AppRoutes.PRIVACY_POLICY} className="text-primary text-sm">
          Privacy Policy
        </Link>
        <div>|</div>
        <FeedbackButton />
      </footer>
      <Toaster />
    </div>
  )
}
