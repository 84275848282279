import { api } from '@/lib/api'
import { formatFileSize } from '@/lib/formatting'
import { LoaderCircleIcon } from 'lucide-react'

export function StatsPage() {
  const { data: stats, isLoading } = api.archives.stats.useQuery()

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <LoaderCircleIcon className="h-8 w-8 animate-spin" />
      </div>
    )
  }

  if (!stats) {
    return <div>No stats available</div>
  }

  return (
    <div className="container mx-auto py-8">
      <h1 className="mb-6 text-3xl font-bold">Archive Statistics</h1>
      <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <StatCard
          title="Total Archives"
          value={stats.totalArchives.toString()}
        />
        <StatCard
          title="On-Chain Archives"
          value={stats.onChainArchives.toString()}
        />
        <StatCard
          title="On-Chain Data"
          value={formatFileSize(stats.totalOnChainSize)}
        />
      </div>
    </div>
  )
}

function StatCard({ title, value }: { title: string; value: string }) {
  return (
    <div className="rounded-lg bg-white p-4 shadow-md">
      <h2 className="text-lg font-semibold text-gray-700">{title}</h2>
      <p className="mt-2 text-3xl font-bold text-indigo-600">{value}</p>
    </div>
  )
}
