import { AppRoutes } from '@/routes'
import { Link } from 'react-router-dom'
import { Card, CardHeader, CardContent, CardTitle, CardFooter } from './ui/card'
import { IArchive } from '@/types'
import { Badge } from './ui/badge'
import { formatDateLong, formatDateWithTime } from '@/lib/formatting'

interface Props {
  archive: IArchive
}

export function ArchiveCard(props: Props) {
  const { archive } = props

  return (
    <Link to={AppRoutes.buildArchiveRoute(archive.url)} key={archive._id}>
      <Card className="relative transition-all hover:drop-shadow-xl">
        <CardHeader>
          {!!archive.txid && (
            <div className="flex items-center gap-2">
              <Badge variant="theme">On-chain</Badge>
              <Badge variant={archive.fullText ? 'default' : 'secondary'}>
                {archive.fullText ? 'Full Text' : 'Hash Only'}
              </Badge>
            </div>
          )}
          <CardTitle className="leading-tight">
            {archive.title || new URL(archive.url).hostname}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="line-clamp-2">{archive.excerpt ?? archive.url}</p>
        </CardContent>
        <CardFooter className="mt-4 text-sm font-bold">
          <p title={formatDateWithTime(archive.date)}>
            {formatDateLong(archive.date)}
          </p>
        </CardFooter>
      </Card>
    </Link>
  )
}
