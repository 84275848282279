import { useMemo, useState } from 'react'
import { api } from '@/lib/api'
import { toast } from '@/components/ui/use-toast'
import { useAnalytics } from '@/hooks/useAnalytics'
import { IArchive } from '@/types'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import {
  XCircleIcon,
  HardDriveUploadIcon,
  BitcoinIcon,
  PencilIcon,
  LoaderCircleIcon,
} from 'lucide-react'
import { Button, buttonVariants } from '@/components/ui/button'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs'
import PayPlugin from '@/components/PayPlugin'
import { PAYMENT_PAYMAIL, EMAILS } from '@/lib/constants'
import { formatCurrency } from '@/lib/formatting'
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@/components/ui/tooltip'

interface Props {
  archive: IArchive
  refetchArchive: () => void
  payModal: boolean
  setPayModal: (payModal: boolean) => void
}

export function Unsaved(props: Props) {
  const { archive, refetchArchive, payModal, setPayModal } = props

  const analytics = useAnalytics()

  const defaultTitle = archive.title
    ? archive.title
    : archive.plainText
      ? archive.textContent.trim().split('\n')[0].slice(0, 100).trim()
      : ''

  const [title, setTitle] = useState(defaultTitle)
  const [editingTitle, setEditingTitle] = useState(!archive.title)
  const [freeArchivalModal, setFreeArchivalModal] = useState(false)

  const { data: walletBalance, isLoading: loadingBalance } =
    api.ordinals.walletBalance.useQuery()

  const { data: fees } = api.ordinals.estimateFeesUsd.useQuery(
    { urls: [archive.url] },
    { enabled: archive && !archive.txid, refetchOnWindowFocus: !payModal },
  )

  const mutation = api.archives.requestFreeArchival.useMutation({
    onSuccess: () => {
      toast({
        title: 'Request Sent',
        description: 'Our team will review your request shortly.',
      })

      setFreeArchivalModal(false)
      refetchArchive()
    },
    onError: (error) => {
      console.error(error)

      toast({ title: 'Error Making Request', description: error.message })
    },
  })

  const standardFees = fees?.standardFees ?? 0
  const premiumFees = fees?.premiumFees ?? 0

  const memoizedUrl = useMemo(() => archive.url, [archive.url])

  const showPayment =
    walletBalance &&
    walletBalance.total_usd > standardFees &&
    walletBalance.total_usd > premiumFees

  return (
    <>
      <Alert className="border-destructive">
        <XCircleIcon className="stroke-destructive h-4 w-4" />
        <AlertTitle>Not Saved</AlertTitle>
        <AlertDescription>
          <div>
            This page is not saved on Bitcoin. Save it now to ensure its
            preservation.
          </div>
          <div className="mt-3 flex flex-col items-center gap-4 sm:flex-row">
            <Dialog
              open={freeArchivalModal}
              onOpenChange={setFreeArchivalModal}
            >
              {archive.freeArchivalRequestedAt ? (
                <>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div
                          aria-disabled
                          className={buttonVariants({
                            variant: 'outline',
                            size: 'sm',
                            className:
                              'pointer-default w-full hover:bg-transparent aria-disabled:opacity-50 sm:w-auto',
                          })}
                        >
                          <HardDriveUploadIcon className="pointer-pointer mr-2 h-5 w-5" />{' '}
                          Request Free Archival
                        </div>
                      </TooltipTrigger>
                      {archive.freeArchivalRequestedAt && (
                        <TooltipContent side="bottom">
                          This page has already been requested to be archived.
                          <br />
                          You may wait for our team to review it or pay to save
                          it on Bitcoin yourself.
                        </TooltipContent>
                      )}
                    </Tooltip>
                  </TooltipProvider>
                </>
              ) : archive.title ? (
                <Button
                  onClick={() => {
                    analytics.sendEvent('request_free_archival', {
                      url: archive.url,
                    })

                    mutation.mutate({ url: archive.url, title: archive.title })
                  }}
                  disabled={mutation.isPending}
                  className="w-full sm:w-auto"
                  variant="outline"
                  size="sm"
                >
                  <HardDriveUploadIcon className="mr-2 h-5 w-5" /> Request Free
                  Archival
                  {mutation.isPending && (
                    <LoaderCircleIcon className="ml-2 h-5 w-5 animate-spin" />
                  )}
                </Button>
              ) : (
                <>
                  <DialogTrigger asChild>
                    <Button
                      onClick={() =>
                        analytics.sendEvent('request_free_archival', {
                          url: archive.url,
                        })
                      }
                      className="w-full sm:w-auto"
                      variant="outline"
                      size="sm"
                    >
                      <HardDriveUploadIcon className="mr-2 h-5 w-5" /> Request
                      Free Archival
                    </Button>
                  </DialogTrigger>
                </>
              )}
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Request Free Archival</DialogTitle>
                  <DialogDescription asChild>
                    <div>
                      <div>
                        If you don't wish to pay to archive the page, you can
                        request a free archival. Our team reviews all requested
                        archivals in order to reduce spam.
                      </div>
                      <div className="mt-8 flex flex-col items-start">
                        <Label
                          htmlFor="free-archival-title"
                          className="mb-2 block text-black"
                        >
                          Title
                        </Label>
                        <Input
                          id="free-archival-title"
                          value={title}
                          disabled={!!archive.title || !editingTitle}
                          onChange={(e) =>
                            setTitle(e.target.value.slice(0, 100))
                          }
                          placeholder="Enter a title for this archive"
                        />
                      </div>
                      <div className="flex justify-center">
                        <Button
                          variant="theme"
                          onClick={() =>
                            mutation.mutate({ url: archive.url, title })
                          }
                          disabled={
                            mutation.isPending ||
                            !!archive.freeArchivalRequestedAt
                          }
                          className="mt-6"
                        >
                          Request Free Archival{' '}
                          {mutation.isPending && (
                            <LoaderCircleIcon className="animate-spin" />
                          )}
                        </Button>
                      </div>
                    </div>
                  </DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog>
            {showPayment && (
              <Dialog open={payModal} onOpenChange={setPayModal}>
                <DialogTrigger asChild>
                  <Button
                    variant="theme"
                    size="sm"
                    onClick={() =>
                      analytics.sendEvent('save_on_bitcoin', {
                        url: archive.url,
                      })
                    }
                    className="w-full sm:w-auto"
                  >
                    <BitcoinIcon className="mr-2" /> Save on Bitcoin
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Save on Bitcoin</DialogTitle>
                    <DialogDescription asChild>
                      <div className="mt-2">
                        <div className="mt-8 flex flex-col items-start">
                          <Label
                            htmlFor="title"
                            className="mb-2 block text-black"
                          >
                            Title
                          </Label>
                          <div className="flex w-full items-center gap-2">
                            <Input
                              id="title"
                              value={title}
                              disabled={!!archive.title || !editingTitle}
                              onChange={(e) =>
                                setTitle(e.target.value.slice(0, 100))
                              }
                              placeholder="Enter a title for this archive"
                            />
                            {!editingTitle && !archive.title && (
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={() => setEditingTitle(true)}
                              >
                                Edit <PencilIcon className="ml-1 h-4 w-4" />
                              </Button>
                            )}
                          </div>
                          {editingTitle && (
                            <Button
                              variant="theme"
                              onClick={() => setEditingTitle(false)}
                              className="mt-4"
                            >
                              Continue
                            </Button>
                          )}
                        </div>

                        {!editingTitle && (
                          <div className="mt-12 flex justify-center">
                            <Tabs
                              defaultValue="standard"
                              className="flex w-[400px] flex-col items-center"
                            >
                              <TabsList className="">
                                <TabsTrigger value="standard">
                                  Hash only ({formatCurrency(standardFees)})
                                </TabsTrigger>
                                <TabsTrigger value="premium">
                                  Full text ({formatCurrency(premiumFees)})
                                </TabsTrigger>
                              </TabsList>

                              <TabsContent value="standard" className="mt-16">
                                <PayPlugin
                                  paymail={PAYMENT_PAYMAIL}
                                  sessionId={memoizedUrl}
                                  productName={`Ark Archive - ${memoizedUrl}`}
                                  price={standardFees}
                                  receiptEmail={EMAILS.RECEIPT}
                                  callbackUrl={`https://ark.page/api/callbacks/payment?premium=false&title=${encodeURIComponent(title)}`}
                                />
                              </TabsContent>
                              <TabsContent value="premium" className="mt-16">
                                <PayPlugin
                                  paymail={PAYMENT_PAYMAIL}
                                  sessionId={memoizedUrl}
                                  productName={`Ark Archive - ${memoizedUrl}`}
                                  price={premiumFees}
                                  receiptEmail={EMAILS.RECEIPT}
                                  callbackUrl={`https://ark.page/api/callbacks/payment?premium=true&title=${encodeURIComponent(title)}`}
                                />
                              </TabsContent>
                            </Tabs>
                          </div>
                        )}
                      </div>
                    </DialogDescription>
                  </DialogHeader>
                </DialogContent>
              </Dialog>
            )}
            {loadingBalance && <LoaderCircleIcon className="animate-spin" />}
          </div>
        </AlertDescription>
      </Alert>
    </>
  )
}
