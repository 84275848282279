import { api } from '@/lib/api'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { InfoIcon, LoaderCircleIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { formatCurrency } from '@/lib/formatting'
import { Helmet } from 'react-helmet'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { ArchiveTable } from './components/ArchiveTable'

const DEFAULT_PAGE_SIZE = 25
const INITIAL_PAGE = 1

export function AdminPage() {
  const { adminSecret } = useParams<{ adminSecret: string }>()

  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)

  const {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,

    isFetchingNextPage,
    fetchNextPage,
    fetchPreviousPage,
    refetch,
  } = api.admin.approvalRequired.useInfiniteQuery(
    { secret: adminSecret ?? '', limit: pageSize },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      enabled: !!adminSecret,
      initialCursor: INITIAL_PAGE,
    },
  )

  const { data: walletBalance } = api.ordinals.walletBalance.useQuery()
  const { data: btcInfo } = api.ordinals.btcFees.useQuery()

  if (isLoading) {
    return (
      <div className="flex justify-center pt-8 lg:pt-14">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    )
  }

  if (!adminSecret) return <div>Unauthorized.</div>

  return (
    <div className="pt-8">
      <Helmet title="Admin | Ark" />
      <div className="flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
        <h1 className="mb-1 text-3xl font-bold">Admin</h1>
        <div className="flex items-center gap-2">
          <div>
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="outline" className="flex items-center gap-1.5">
                  BTC Info <InfoIcon className="h-4 w-4" />
                </Button>
              </DialogTrigger>
              <DialogContent>
                <pre>{JSON.stringify(btcInfo, null, 2)}</pre>
              </DialogContent>
            </Dialog>
          </div>
          <div>
            Wallet Balance: {formatCurrency(walletBalance?.total_usd ?? 0)}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <ArchiveTable
          archives={data?.pages.map((group) => group.archives).flat() ?? []}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          pageSize={pageSize}
          currentPage={currentPage}
          isFetchingNextPage={isFetchingNextPage}
          adminSecret={adminSecret}
          walletBalance={walletBalance?.total_usd}
          refetchData={refetch}
          getNextPage={fetchNextPage}
          getPreviousPage={fetchPreviousPage}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  )
}
