import { Helmet } from 'react-helmet'

export function PrivacyPolicyPage() {
  return (
    <div className="prose prose-sm mx-auto mt-10 w-full">
      <Helmet title="Privacy Policy | Ark" />
      <h1>PRIVACY POLICY</h1>

      <p>
        <em>Last updated February 03, 2020</em>
      </p>

      <p>
        Thank you for choosing to be part of Ark ("Company", "we", "us", or
        "our"). We are committed to protecting your personal information and
        your right to privacy. If you have any questions or concerns about our
        notice, or our practices with regards to your personal information,
        please contact us at info@ark.page.
      </p>

      <p>
        When you visit our mobile application, and use our services, you trust
        us with your personal information. We take your privacy very seriously.
        In this privacy notice, we seek to explain to you in the clearest way
        possible what information we collect, how we use it and what rights you
        have in relation to it.
      </p>

      <p>
        This privacy notice applies to all information collected through our
        mobile application, ("Apps"), and/or any related services, sales,
        marketing or events (we refer to them collectively in this privacy
        notice as the "Services").
      </p>

      <p>
        Please read this privacy notice carefully as it will help you make
        informed decisions about sharing your personal information with us.
      </p>

      <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>

      <p>
        <strong>In Short:</strong> The only information we retain from your
        account is your paymail address. And your email if you choose to sing up
        for our Early Access program.
      </p>

      <h2>2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>

      <p>
        <strong>In Short:</strong> We only share information with your consent,
        to comply with laws, to provide you with services, to protect your
        rights, or to fulfill business obligations.
      </p>

      <p>We may process or share data based on the following legal basis:</p>

      <ul>
        <li>
          Consent: We may process your data if you have given us specific
          consent to use your personal information in a specific purpose.
        </li>
        <li>
          Legitimate Interests: We may process your data when it is reasonably
          necessary to achieve our legitimate business interests.
        </li>
        <li>
          Performance of a Contract: Where we have entered into a contract with
          you, we may process your personal information to fulfill the terms of
          our contract.
        </li>
        <li>
          Legal Obligations: We may disclose your information where we are
          legally required to do so in order to comply with applicable law,
          governmental requests, a judicial proceeding, court order, or legal
          process, such as in response to a court order or a subpoena (including
          in response to public authorities to meet national security or law
          enforcement requirements).
        </li>
        <li>
          Vital Interests: We may disclose your information where we believe it
          is necessary to investigate, prevent, or take action regarding
          potential violations of our policies, suspected fraud, situations
          involving potential threats to the safety of any person and illegal
          activities, or as evidence in litigation in which we are involved.
        </li>
      </ul>

      <p>
        More specifically, we may need to process your data or share your
        personal information in the following situations:
      </p>

      <ul>
        <li>Vendors, Consultants and Other Third-Party Service Providers.</li>
        <li>Business Transfers.</li>
        <li>Other Users.</li>
      </ul>

      <h2>3. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

      <p>
        <strong>In Short:</strong> We keep your information for as long as
        necessary to fulfill the purposes outlined in this privacy notice unless
        otherwise required by law.
      </p>

      <h2>4. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

      <p>
        <strong>In Short:</strong> We aim to protect your personal information
        through a system of organizational and technical security measures.
      </p>

      <h2>5. DO WE COLLECT INFORMATION FROM MINORS?</h2>

      <p>
        <strong>In Short:</strong> We do not knowingly collect data from or
        market to children under 18 years of age.
      </p>

      <h2>6. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

      <p>
        <strong>In Short:</strong> You may review, change, or terminate your
        account at any time.
      </p>

      <h3>Account Information</h3>

      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>

      <ul>
        <li>Contact us using the contact information provided.</li>
      </ul>

      <h2>7. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. No uniform
        technology standard for recognizing and implementing DNT signals has
        been finalized.
      </p>

      <h2>8. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

      <p>
        <strong>In Short:</strong> Yes, if you are a resident of California, you
        are granted specific rights regarding access to your personal
        information.
      </p>

      <h2>9. DO WE MAKE UPDATES TO THIS POLICY?</h2>

      <p>
        <strong>In Short:</strong> Yes, we will update this policy as necessary
        to stay compliant with relevant laws.
      </p>

      <h2>10. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h2>

      <p>
        If you have questions or comments about this policy, you may email us at
        info@ark.page.
      </p>
    </div>
  )
}
