import { feedbackIntegration } from '@sentry/react'
import { FlagIcon } from 'lucide-react'
import { useEffect } from 'react'
import { Button } from './ui/button'

const feedback = feedbackIntegration({
  autoInject: false,
  colorScheme: 'light',
  showBranding: false,
})

export function FeedbackButton() {
  useEffect(() => {
    if (import.meta.env.PROD) {
      feedback.attachTo(document.getElementById('feedback-button')!)
    }
  }, [])

  return (
    <Button
      id="feedback-button"
      variant="link"
      className="gap-1.5 p-0 font-normal"
    >
      <FlagIcon className="h-4 w-4" />
      Report a problem
    </Button>
  )
}
